/* Site level defaults (ie. font-family, resets, etc) */

@tailwind base;

body {
    @apply text-base;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
