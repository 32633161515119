/* App level defaults and overrides */
@tailwind components;
@tailwind utilities;

/* Resusable components */
@layer components {
    .btn {
        @apply px-3 py-2 border rounded;
    }
    
    .form-control {
        @apply border border-gray-400 px-3 py-2 rounded;
    }

    .wrapper {
        @apply w-[90%] max-w-[1200px] mx-auto;
    }
}

body {
    @apply text-gray-800 leading-[1.4] text-[17px];
    color: #1C1D1F;
    font-family: 'Nunito', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

h1,
.h1 {
    @apply font-bold text-3xl;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

h2,
.h2 {
    @apply font-bold text-2xl;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

h3,
.h3 {
    @apply font-bold text-xl;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

h4,
.h4 {
    @apply font-bold text-lg;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

/* Staggered margins for Y axis sibling elements */
.flow-y > * + *:not(.no-flow),
.flow-y-base > * + *:not(.no-flow) {
    @apply mt-4
}
.flow-y-sm > * + *:not(.no-flow) {
    @apply mt-2
}
.flow-y-lg > * + *:not(.no-flow) {
    @apply mt-8
}

/* Staggered margins for X axis sibling elements */
.flow-x > * + *:not(.no-flow),
.flow-x-base > * + *:not(.no-flow) {
    @apply ml-4
}
.flow-x-sm > * + *:not(.no-flow) {
    @apply ml-2
}
.flow-x-lg > * + *:not(.no-flow) {
    @apply ml-8
}

.tp-gradient {
    background-color: #0057B7;
    background: linear-gradient(90deg, #0057B7 0%, #0081a1 100%);
}

@media print {
    .no-print {
        display: none;
    }

    .print {
        display: block;
    }
}
